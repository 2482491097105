<script>
  import ErrorMessage from "./ErrorMessage.svelte";
  import { _ } from "svelte-i18n";
</script>

<section class="instalment-agreement">
  <input id="instalment-agreement" type="checkbox" required />
  <label for="instalment-agreement"><slot /></label>
  <ErrorMessage>{$_("details.Please check this box to continue")}</ErrorMessage>
</section>

<style>
  @import "../styles/variables.css";

  .instalment-agreement {
    margin: 0.5rem 0 1rem 0;
  }

  .instalment-agreement input {
    display: none;
  }

  .instalment-agreement label {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-style: italic;
    padding: 0.5rem;
  }

  .instalment-agreement label::before {
    color: var(--primary);
    content: "☐";
    font-size: 2rem;
    margin-right: 0.5rem;
    font-style: normal;
  }

  .instalment-agreement input:checked + label::before {
    content: "☑";
  }

  :global(.show-errors) .instalment-agreement input:invalid + label {
    color: var(--secondary);
    border: 2px solid var(--secondary);
    border-radius: 3px;
    background: var(--secondary-light);
  }

  :global(.show-errors) .instalment-agreement input:invalid + label::before {
    text-shadow: var(--primary-light) 0 0 5px;
  }
</style>
